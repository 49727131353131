import React from "react";
import "bulma/css/bulma.css";
import "../styles/global.css";

import ItemCard from "../components/item";

import HeartShape from "../../static/heartShape.png";

import SubCollection from "../components/subCollection";
import CardImage from "../../static/card.jpeg";
import FirstImage from "../../static/firstSlide.jpg";
const imgUrls = [
  `${FirstImage}`,
  "https://cmeimg-a.akamaihd.net/640/clsd/getty/c64f76dc20c246ca88ee180fe4b4b781",
  "https://lh3.googleusercontent.com/oxPeODS2m6rYIVbhcQChRtOWEYeGDwbeeeB1cDU2o_WYAVPU61VIgx-_6BAh5gSL8Sw=h900",
  "https://i0.wp.com/www.universodegatos.com/wp-content/uploads/2017/04/fivfelv7.jpg?resize=582%2C328",
  "https://i.pinimg.com/736x/07/c3/45/07c345d0eca11d0bc97c894751ba1b46.jpg",
  "https://ehealthforum.com/health/images/avatars/11699147425707699031013.jpeg"
];
class Collection extends React.Component {
  render() {
    return (
      <SubCollection
        startImage={HeartShape}
        textSection="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus element"
        imgUrls={imgUrls}
        cardTitle="Tailored heart necklace"
        cardText="18kt Gold and Sterling Silver necklaces adorned with heart shaped gems."
      >
        <ItemCard image={CardImage}></ItemCard>
      </SubCollection>
    );
  }
}
export default Collection;
